<template>
  <div class="body">
    <div class="content clearfix">
      <div class="content_wrap">
        <div class="document">
          <div class="document_wrap view_detail">
            <div class="system_head">
              <div class="system_logo"><a><img class="system_img_login" src="/img/main/logo.svg"></a></div>
              <h2 class="system_title system_title_login">〜シフトから給与までを自動に〜</h2>
            </div>

            <div class="login_alert" style="background-color:#F78181;color:#F7FE2E;margin:20vh 0;">
              URLが違うか、すでに入社手続きを送信済みです。
            </div>

            <div class="bottom_wrap clearfix">
              <br>
              <div class="admin">
                <p>
                  Copyright ©
                  2019 S&amp;A Co.,Ltd All rights reserved.
                </p>
              </div>
            </div>
          </div><!--document_wrap-->
        </div><!--document-->
        <div id="popup">
        </div>

        <div id="logout_popup">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins'
export default {
  mixins: [mixins],
  data: () => ({

  }),
  methods: {

  },
  created() {
    this.loaded();

    util.emitter.on('updateCache', async () => {
    });
  },
  computed: {

  },
}

</script>

<style lang="scss">
@charset "UTF-8";
@import '@/styles/index.scss';
@include common {
  .login_alert {
    width: 100%;
    margin: 10px 0;
    height: 50px;
    padding: 0 10px 0 15px;
    line-height: 50px;
    border-radius: 10px;
    background-color: #f9f3a3;
    font-size: 16px;
    font-weight: 600;
    color: #3e7c95;
  }

  .system_logo, .system_title {
    display: inline-block;
    vertical-align: middle;
  }

  .system_title_login {
    padding-top: 35px;
    font-weight: 800;
  }

  .system_img_login {
    height: 50px;
    margin-top: 2px;
    margin-bottom: 0px;
    margin-left: 50px;
  }
  .body {
    height: 100%;
    background-image: url(/img/main/bg_header.png);
    background-repeat: repeat;
  }

  .content{
    width:100%;
    height: 100%;
    min-height: 100vh;
    height: auto !important;
    padding-bottom: 20px;
    background-image:
    url(/img/main/shadow_01.png),
    url(/img/main/shadow_02.png),
    url(/img/main/shadow_03.png),
    url(/img/main/shadow_04.png);
    background-repeat: no-repeat;
    background-position:
    top 120px left 70px,
    top 120px right 100px,
    bottom 50px left 100px,
    bottom -10px right 110px;
    position:relative;
  }
  .content_wrap{
    width: 100%;
    min-width: 660px;
    max-width: 1200px;
    padding: 30px 15px 20px;
    margin: 0 auto;
  }

  /*================================
  head
  ==================================*/

  .system_head{
    width: 100%;
    padding: 5px 0 0 2%;
    margin-bottom: 50px;
  }
  .system_logo,.system_title{
    display: inline-block;
    vertical-align: middle;
  }
  h1.system_title{
    font-size: 20px;
    font-weight: 600;
    margin-left: 15px;
  }


  /*================================
  login_box
  ==================================*/

  .login_box{
    width: 97%;
    margin: 0 auto 40px;
    background-color: #6fb5d0;
    padding: 50px;
    border-radius: 15px;
  }
  .login_box p{
    line-height: 30px;
  }
  .name{
    margin-right: 30px;
    font-size:20px;
  }
  .company_logo{
    margin-right: 15px;
  }
  .company_logo img{
    vertical-align:text-bottom;
  }
  .company_name{
    font-weight: 600;
    font-size:20px;
  }

  .login_alert{
    width: 100%;
    margin: 30px 0;
    height:70px;
    padding: 0 15px 0 30px;
    line-height: 70px;
    border-radius: 15px;
    background-color: #f9f3a3;
    font-size: 20px;
    font-weight: 600;
    color: #3e7c95;
  }

  select.login_category{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    margin: 30px 0;
    height:70px;
    padding: 0 15px 0 30px;
    line-height: 70px;
    border-radius: 15px;
    background-color:#F6ED76;
    font-size: 20px;
    font-weight: 600;
    color: #3e7c95;
    background-image: url(/img/main/icon_sort_blue.png);
    background-repeat: no-repeat;
    background-position: center right 15px;
  }

  .id_pass{
    width: 100%;
    margin: 15px 0;
    height:50px;
    line-height: 50px;
  }
  .id_pass img{
    width: 4%;
    vertical-align: middle;
    margin:0 4% 0 14%;
  }
  .id_pass input{
    width: 60%!important;
    line-height: 40px!important;
    padding:0 10px!important;
    font-size: 14px!important;
    height:inherit!important;
    border:inherit!important;
    border-radius:4px!important;
    background-color:white!important;
  }
  #loginBtn{
    display: block;
    margin: 40px auto 0;
    width: 280px;
    height:50px;
    background-color: #1c5469;
    border: solid 3px #74c0ec;
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
    opacity: 0.8;
  }
  #loginBtn:hover{
    opacity: 1;
  }


  /*================================
  bottom_wrap
  ==================================*/

  .bottom_wrap{
    width: 930px;
    position: absolute;
    bottom:5px;left:55%;
    margin-left: -465px;
  }
  .bottom_nav ul li a{
    display: block;
    width: 17%;
    line-height: 25px;
    margin: 0 1.5%;
    text-align: center;
    border: solid 1px #1D6F94;
    color:#1D6F94;
    border-radius: 10px;
    float: left;
  }
  .bottom_nav ul li a:hover{
    background-color: #1D6F94;
    color: #ABE7FC;
  }
  .contact{
    width: 300px;
    margin: 20px auto;
  }
  .contact a{
    color:#27A1D1;
    text-decoration: underline;
  }
  .admin{
    text-align: right;
  }

};
@include over {

};
@include pc {
  .content{
    width:100%;
    height: 100%;
    min-height: 100vh;
    height: auto !important;
    padding-bottom: 100px;
    background-image:
    url(/img/main/shadow_01.png),
    url(/img/main/shadow_03.png),
    url(/img/main/shadow_04.png);
    background-repeat: no-repeat;
    background-position:
    top 50px right 40px,
    bottom 50px right 100px,
    bottom 40px left -10px;
    position:relative;
  }
};
@include tab {
  .system_head {
    width: 100%;
    padding: 5px 0 0 2%;
    margin-bottom: 20px;
  }
  .system_head {
    width: 100%;
    padding: 5px 0 0 2%;
    margin-bottom: 50px;
  }

  .system_logo, .system_title {
    display: inline-block;
    vertical-align: middle;
  }
  .system_logo, .system_title {
    display: inline-block;
    vertical-align: middle;
  }

  .system_img_login {
    margin-bottom: 0px;
    margin-top: 0px;
    margin-left: 10px;
    height: 40px;
  }

  .system_title_login {
    display: none!important;
  }



  .content{
    width:100%;
    height:100%;
    padding-bottom:0;
    background-image: url(/img/main/bg_header.png);
    background-repeat: repeat;
  }
  .content_wrap{
    width: 90%;
    min-width: 0;
    max-width: 90%;
    padding: 20px 0 0;
    margin: 0 auto;
  }

  .system_head{
    width: 100%;
    padding: 5px 0 0 2%;
    margin-bottom: 20px;
  }
  .system_logo,.system_title{
    display: inline-block;
    vertical-align: middle;
  }
  h1.system_title{
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
  }

  .login_alert{
    width: 100%;
    margin: 10px 0;
    height:50px;
    padding: 0 10px 0 15px;
    line-height: 50px;
    border-radius: 10px;
    background-color: #f9f3a3;
    font-size: 16px;
    font-weight: 600;
    color: #3e7c95;
  }

  .login_box{
    width: 100%;
    margin: 0 auto 30px;
    background-color: #6fb5d0;
    padding: 20px;
    border-radius: 15px;
  }
  .login_box p{
    line-height: 30px;
  }
  .name{
    margin-right:0;
    display: block;
  }
  .company_logo{
    margin-right: 10px;
  }
  .company_logo img{
    vertical-align:text-bottom;
  }
  .company_name{
    font-weight: 600;
  }

  select.login_category{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    margin: 10px 0;
    height:40px;
    padding: 0 15px 0 30px;
    line-height: 40px;
    border-radius: 15px;
    background-color:#F6ED76;
    font-size: 15px;
    font-weight: 600;
    color: #3e7c95;
    background-image: url(/img/main/icon_sort_blue.png);
    background-repeat: no-repeat;
    background-position: center right 15px;
  }

  .id_pass{
    width: 100%;
    margin: 15px 0;
    height:30px;
    line-height: 30px;
  }
  .id_pass img{
    width: 8%;
    vertical-align: middle;
    margin:0 3% 0 1%;
  }
  .id_pass input{
    width: 88%!important;
    line-height: 30px!important;
    padding:0 10px!important;
    font-size: 13px!important;
    height:inherit!important;
    border:inherit!important;
    border-radius:4px!important;
  }
  #loginBtn{
    display: block;
    margin: 30px auto 0;
    width: 80%;
    height:50px;
    background-color: #1c5469;
    border: solid 3px #74c0ec;
    border-radius: 10px;
    color: #fff;
    font-size: 18px;
  }
  #loginBtn:hover{
    background-color:#27A1D1;
    border: solid 2px #1c5469;
  }

  .bottom_wrap{
    width: 100%;
    position: static;
    margin: 20px auto 5px;
  }
  .bottom_nav ul li a{
    display: block;
    width: 47%;
    line-height: 25px;
    margin: 0 1.5% 10px;
    text-align: center;
    border: solid 1px #1D6F94;
    color:#1D6F94;
    border-radius: 10px;
    float: left;
  }
  .bottom_nav ul li a:hover{
    background-color: #1D6F94;
    color: #ABE7FC;
  }
  .contact{
    clear: both;
    width: 300px;
    margin: 20px auto;
  }
  .contact a{
    color:#27A1D1;
    text-decoration: underline;
  }
  .admin{
    text-align: center;
  }
};
@include sp {

};
</style>
